@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap');
/* .App {
	display: flex;
	justify-content: center;
} */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
.appHolder {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
}
.contentHolder {
  flex: 1;
  overflow-y: auto;
}
.content {
  /* height: 100vh; */
}
/* .list-item-holder {
	margin-bottom:40px;
} */
.img-gradient {
  position: relative;
  display: inline-block;
}
.active {
  color: #861914 !important;
}
.notActive {
  color: black;
}
h4.title {
  color: white;
  z-index: 100;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 0px 20px;
  display: block;
  font-weight: 400;
  font-size: 18px;
}
.img-gradient:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0)),
    color-stop(60%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
.img-gradient img {
  display: block;
  max-width: 100%;
  height: auto;
}
span.MuiButton-label {
  text-transform: initial;
  font-family: 'Poppins', san-serif;
  font-size: 16px;
  margin-right: 8px;
  margin-left: 8px;
}
.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 180;
}
.albumSelector {
  height: 48px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  z-index: 200;
  background-color: white;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* border-bottom: 3px solid #ffffff; */
}
.albumSelector::-webkit-scrollbar {
  display: none;
}
.currentSelection.active {
  border-bottom: 5px solid #861914;
  border-radius: 0;
  margin-top: 3px;
}
.material-icons {
  line-height: inherit;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.video-info {
  position: absolute;
}
.MuiBottomNavigation-root {
  border-top: 1px solid #861914;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #861914 !important;
}
.MuiLinearProgress-root {
  background-color: #ffffff !important;
  height: 5px !important;
}

.list-item-holder:first-of-type {
  margin-top: 108px;
}
.white-text {
  color: white;
}
.bottomMargin {
  margin-bottom: 60px;
}
.weather-wrapper {
  background-color: white;
  padding-top: 4px;
}
p.weatherbanner-title {
  font-size: 15px;
  padding-left: 10px;
  margin-bottom: 4px;
  background-color: white;
  padding-top: 8px;
  margin-top: 0;
  text-align: center;
}
.weather-banner {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 1.5em;
  background-color: #861914;
  color: white;
  max-width: 90%;
  padding: 6px;
  font-size: 13px;
  border: 1px solid #861914;
  margin: 0 auto;
  border-radius: 3px;
}
.weather-banner p {
  margin: 0 12px 0 0;
}
p.wind {
  display: flex;
  align-items: center;
}
p.weather-desc {
  text-transform: lowercase;
}
